import { Tool } from "~/enums/tool";

export default [{
    "key": Tool.ATLASGO.toLowerCase(),
    "name": Tool.ATLASGO,
    "url": "https://atlasgo.io/",
},{
    "key": Tool.LLANA.toLowerCase(),
    "name": Tool.LLANA,
    "url": "https://llana.io/",
},{
    "key": Tool.NUXTJS.toLowerCase(),
    "name": Tool.NUXTJS,
    "url": "https://nuxt.com/",
},{
    "key": Tool.N8N.toLowerCase(),
    "name": Tool.N8N,
    "url": "https://n8n.io/",
},{
    "key": Tool.ELESTIO.toLowerCase(),
    "name": Tool.ELESTIO,
    "url": "https://elest.io/",
},{
    "key": Tool.AWS.toLowerCase(),
    "name": Tool.AWS,
    "url": "https://aws.amazon.com/",
}]
