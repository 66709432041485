<script setup lang="ts">
import stack from '../assets/json/stack'
import results from '../assets/json/client-results'
import { seo, OgType } from '@/helpers/seo'

const route = useRoute()
const title = 'Refreshingly Simple Software Development'
const description = 'Were on a mission to help you focus on the work you love 💛 through technology, automation and tooling. We thrive when were making impossible things possible.'

useSeoMeta({
	titleTemplate: `%s`,
	title,
	ogTitle: title,
	ogType: OgType.WEBSITE,
	ogUrl: route.fullPath,
	description,
	ogDescription: description,
	ogImage: seo.image
})

</script>


<template>
	<div id="index">
		<div class="hero">
			<div class="mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl gap-4 sm:gap-y-8 flex flex-col">
					<div class="flex flex-row">
						<div class="sm:w-6/12 w-9/12">
							<div class="text-center">

			<h1 class="text-5xl font-bold tracking-tight sm:text-7xl mt-24">
				Refreshingly <span class="text-primary">simple</span> software development<span class="text-primary">.</span>
			</h1>

			<p class="mt-6 text-lg tracking-tight">
				We're on a mission to help you focus on the work you love 💛 through technology, automation and tooling.
				<br><br>We thrive when we're making impossible things possible.</p>
			<div class="mt-10 flex flex-wrap gap-x-6 gap-y-3 justify-center">
				<a type="button"
					class="button-secondary"
					href="/automations"
					>
					<Icon name="ph:tree-structure-duotone" class="flex-shrink-0 h-6 w-6 align-[-5px]" /> Workflow Automation & AI
				</a>

				<a type="button"
					class="button-primary"
					href="/rapid">
					<Icon name="ph:rocket-launch-duotone" class="flex-shrink-0 h-6 w-6 align-[-5px]" /> Rapid Development
				</a>

			</div>

						</div>
						</div>
						</div>



			</div>
		</div>


		<!--CTAs-->

		<div class="pt-2 pb-16 sm:pt-4 sm:pb-24">
				<div class="mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl gap-16 items-center text-center sm:gap-y-24 grid lg:grid-cols-3 ">
					<div>
						<h2 class="text-4xl font-bold tracking-tight">Streamline Workflows with Expert <span class="text-primary">Consultation</span></h2>
						<p class="mt-6 text-lg/8"><span>Our consultation services help businesses implement workflow automation seamlessly. By analyzing your processes, we design tailored solutions for efficiency, productivity, and cost savings. Maximize your potential with streamlined workflows and optimized resource allocation.</span></p>

					</div>

					<div>
						<h2 class="text-4xl font-bold tracking-tight"><span class="text-primary">Build</span> Innovative No-Code & Low-Code Solutions</h2>
						<p class="mt-6 text-lg/8"><span>We develop cutting-edge, code-free or low-code applications. Automate tasks, boost collaboration, and streamline operations without the need for extensive programming knowledge. Stay ahead in a rapidly evolving marketplace with intuitive, simplified solutions.</span></p>

					</div>

					<div>
						<h2 class="text-4xl font-bold tracking-tight"><span class="text-primary">Unleash</span> Possibilities with Full-Code Applications</h2>
						<p class="mt-6 text-lg/8">Going beyond no-code or low-code by building robust full-code applications when needed. Our developers utilize advanced coding practices and our modular development framework to transform your vision into a scalable and future-proof applications.</p>

					</div>
				</div>

			</div>

		<!-- Results -->
		<div class="py-8">
				<div class="mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl gap-4 sm:gap-y-8 flex flex-col">
					<div class="text-center flex flex-col items-center">
						<h2 class="text-3xl font-bold tracking-tight text-white sm:text-4xl lg:text-5xl">
							Client <span class="text-primary">Results</span>
						</h2>
					</div>
					<div class="grid grid-cols-1 sm:grid-cols-2 gap-8 xl:grid-cols-3">
						<div
							class="card"
							v-for="item in results" :key="item.client">
								<ClientResultCard :item="item" />
						</div>
					</div>
				</div>
			</div>

		<!-- Apps -->

		<div class="py-24 sm:py-32">
			<div class="mx-auto px-4 sm:px-6 lg:px-8 max-w-7xl gap-16 sm:gap-y-24 grid lg:grid-cols-2 lg:items-center">
			<div class="">
				<h2 class="text-3xl font-bold tracking-tight sm:text-4xl lg:text-5xl">
				<span>Ship
					faster with <br><span class="text-primary">our tech stack</span></span>
				</h2>
				<p class="mt-6 text-lg/8 text-gray-600 dark:text-gray-300">
				<span>No vendor lock-in! Every solution we deliver comes with full training to self-administer. At any time you can hire internally or move to a different agency.</span>
				</p>
				<!-- <div class="mt-8 flex flex-wrap gap-x-3 gap-y-1.5">
				<a
					type="button"
					class="button-primary"
					href="/framework/apps"
				>
					<Icon name="ph:puzzle-piece-duotone" class="flex-shrink-0 h-5 w-5" />
					See All Apps
				</a>
				</div> -->
			</div>
			<!-- <div class="grid grid-cols-4 sm:grid-cols-5 md:grid-cols-10 lg:grid-cols-5 gap-4 sm:gap-5 lg:gap-8">
				<template v-for="link in apps" :key="link">
				<NuxtLink v-if="!link.pageNotAvailable" :href="`/framework/apps/${link.key}`" class="block lg:hover:scale-110 transition">
					<img :src="`/assets/images/apps/${link.key}.png`" :alt="link.key" loading="lazy" class="rounded-xl">
				</NuxtLink>
				<div v-else class="block lg:hover:scale-110 transition">
					<img :src="`/assets/images/apps/${link.key}.png`" :alt="link.key" loading="lazy" class="rounded-xl">
				</div>
				</template>
			</div>-->

			<div class="grid grid-cols-4 sm:grid-cols-5 md:grid-cols-10 lg:grid-cols-5 gap-4 sm:gap-5 lg:gap-8">
				<template v-for="link in stack" :key="link">
				<NuxtLink v-if="link.url" :href="link.url" target="_blank" class="block lg:hover:scale-110 transition">
					<img :src="`/assets/images/apps/${link.key}.png`" :alt="link.key" loading="lazy" class="rounded-xl">
				</NuxtLink>
				<div v-else class="block lg:hover:scale-110 transition">
					<img :src="`/assets/images/apps/${link.key}.png`" :alt="link.key" loading="lazy" class="rounded-xl">
				</div>
				</template>
			</div>
			</div>
		</div>

		</div>
</template>

<style scoped>
.hero {
	background-image: url('/assets/images/cropped-hero.jpg');
  background-size: auto 85%;
	background-position: top;
	background-repeat: no-repeat;
	height: 100vh;
}
</style>
